.port-view-demo {
  /* border: 1px solid red; */
  padding-top: 40px;

  .p {
    text-align: left;
  }
}

.margin-top-custom {
  margin-top: 8vh;
}


.text-align-custom .p {
  text-align: center !important;
}

.container-demo {
  width: 100% !important;
  padding-left: 5vw !important;
  padding-top: 15vh !important;
  padding-bottom: 5vh !important;
  padding-right: 5vw !important;
}

.whatsapp-floating-button {
  position: fixed;
  z-index: 10;
  bottom: 10px;
  right: 0px;
}

.whatsapp-floating-button img {
  width: 120px; /* Ajuste conforme necessário */
  height: auto; /* Ajuste conforme necessário */
}

.sombra-map{
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}
