.bolinha {
  /* width: 50px;
  height: 50px; */
  position: fixed;
  bottom: 20px;
  right: 20px;
}
.btn-solid-bola{
  border-radius: 50%;
    width: 50px;
  height: 50px;
}