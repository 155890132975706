.fixed-div-view-demo {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(254, 255, 255, 1) 40%, rgba(0, 212, 255, 0) 100%);
    position: fixed !important;
    padding-top: -200px !important;
    padding-top: 6vh !important;
    width: 1000px !important;
    /* Inicialmente torna a div invisível */
    transition: opacity 1s ease !important;
    /* Adiciona uma transição suave */
    z-index: 100 !important;
    top: 0 !important;
    height: 200px;
    width: 100vw !important;
}
.fixed-div-view-demo-invisivel {
    /* outros estilos... */
    height: 0;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: opacity 1s ease !important; /* ou qualquer outra transição que você deseje */
}
.container {
    width: 95%;
}
