.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* cor de fundo semi-transparente */
  display: flex;
  justify-content: center;
  /* centraliza horizontalmente */
  align-items: center;
  /* centraliza verticalmente */
  z-index: 9999;
  /* z-index alto para garantir que fique sobre todos os elementos */
}

.content-modal {
  background-color: rgb(247, 246, 246);
  padding: 6vh;
  max-height: 90vh;
  /* Ou qualquer valor desejado */
  overflow-y: auto;
  width: 80%;
  border-radius: 8px;
}

.content-modal-center {
  text-align: center;
}